import React, { useState, useEffect, useRef } from "react";
import { Zoom, Fade } from "react-awesome-reveal";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { TextureLoader } from "three";

import earthTexture from "./assets/img/earth_texture.jpg";
import "./App.css"; // External CSS for Earth styles

const App = () => {
  const [autoRotate, setAutoRotate] = useState(true);
  const [zoomedIn, setZoomedIn] = useState(false);

  // Generate random pin coordinates (latitude, longitude)
  const generateRandomPins = () => {
    const pins = [];
    for (let i = 0; i < 15; i++) {
      const lat = Math.random() * 180 - 90; // Random latitude between -90 and 90
      const lon = Math.random() * 360 - 180; // Random longitude between -180 and 180
      pins.push({ lat, lon });
    }
    return pins;
  };

  const pinCoordinates = generateRandomPins(); // Generate random pins

  // Convert lat/lon to 3D coordinates on the sphere
  const convertTo3D = (lat, lon) => {
    const radius = 1; // Earth radius (scaled down)
    const phi = (90 - lat) * (Math.PI / 180); // Convert latitude to radians
    const theta = (lon + 180) * (Math.PI / 180); // Convert longitude to radians
    const x = radius * Math.sin(phi) * Math.cos(theta);
    const y = radius * Math.cos(phi);
    const z = radius * Math.sin(phi) * Math.sin(theta);
    return { x, y, z };
  };

  // Auto-rotation logic for Earth
  const Earth = () => {
    const meshRef = useRef();

    useFrame(() => {
      if (autoRotate) {
        meshRef.current.rotation.y += 0.003; // Slow rotation
      }
    });

    return (
      <mesh ref={meshRef}>
        <sphereGeometry args={[1, 32, 32]} /> {/* 3D Sphere geometry */}
        <meshStandardMaterial
          map={new TextureLoader().load(earthTexture)} // Apply Earth texture
          roughness={0.5}
          metalness={0.3}
        />
      </mesh>
    );
  };

  // Pin Animation - dropping pins and making them look more realistic
  const Pin = ({ position }) => {
    const pinRef = useRef();
    const [animation, setAnimation] = useState(false);
    const [pinPosition, setPinPosition] = useState([0, 5, 0]); // Initial position above Earth

    useEffect(() => {
      const dropPin = () => {
        let t = 0; // Time counter
        const interval = setInterval(() => {
          if (t < 1) {
            t += 0.05;
            const y = Math.max(1, pinPosition[1] - t * 0.1); // Falling effect, starts from above and lands on Earth's surface
            setPinPosition([position[0], y, position[2]]);
          } else {
            clearInterval(interval);
          }
        }, 20);
      };
      dropPin();
    }, [position]);

    // Handle cluster expansion when zoomed in
    useEffect(() => {
      if (pinRef.current) {
        const scale = zoomedIn ? 1 : 0.3; // Scale the pin when zoomed in
        pinRef.current.scale.set(scale, scale, scale);
      }
    }, [zoomedIn]);

    return (
      <group ref={pinRef} position={pinPosition}>
        {/* Pin's base - a cone for a more realistic appearance */}
        <mesh>
          <coneGeometry args={[0.05, 0.1, 16]} />
          <meshStandardMaterial color="red" emissive="white" />
        </mesh>

        {/* Pin's head - a small sphere */}
        <mesh position={[0, 0.1, 0]}>
          <sphereGeometry args={[0.05, 16, 16]} />
          <meshStandardMaterial color="red" />
        </mesh>
      </group>
    );
  };

  // Handle zoom in/out event for expanding the pins
  const handleZoom = (state) => {
    setZoomedIn(state.distance < 5); // Trigger expansion when zoomed in (distance < 5)
  };

  return (
    <div className="app-container">
      <Zoom>
        <div className="earth-container">
          {/* 3D Earth with rotating and zooming controls */}
          <Canvas style={{ width: "100%", height: "100vh" }}>
            <Earth />

            {/* Render pins at random positions */}
            {pinCoordinates.map((pin, index) => {
              const { x, y, z } = convertTo3D(pin.lat, pin.lon);
              return <Pin key={index} position={[x, y, z]} />; // Place pins on the Earth
            })}

            {/* Lights for better visibility */}
            <ambientLight intensity={0.4} />
            <directionalLight position={[10, 10, 10]} intensity={0.6} />
            <OrbitControls
              onChange={(e) => handleZoom(e.target)}
              onClick={() => setAutoRotate(!autoRotate)} // Toggle rotation on click
            />
          </Canvas>
        </div>
      </Zoom>
      <Fade>
        <h1 className="coming-soon-text" style={{color: 'rgb(253 237 189)'}}>"Transforming Data into<br /> Prime Solutions"</h1>
        {/* <h1 className="coming-soon-text">Under Development</h1> */}
      </Fade>
    </div>
  );
};

export default App;
